export default name => {
  // eslint-disable-next-line operator-linebreak
  const randString =
    name
      .substring(0, 8)
      .replace(/\s/g, '')
      .toLowerCase() + Math.floor(100 + Math.random() * 900)

  return randString
}
